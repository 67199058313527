import './vendor/bootstrap/css/bootstrap.css'
import './vendor/fontawesome/css/all.min.css'

// Fonts
import './fonts/icons/style.css'

// CSS
import './vendor/animate.css'
import './vendor/owlCarousel/dist/assets/owl.carousel.min.css'
import './vendor/owlCarousel/dist/assets/owl.theme.default.min.css'
import './vendor/magnific-popup/magnific-popup.min.css'

// JS
import './vendor/bootstrap/js/bootstrap'
import './vendor/jquery-viewport-checker/dist/jquery.viewportchecker.min.js'
import './vendor/owlCarousel/dist/owl.carousel.min.js'
import './vendor/magnific-popup/magnific-popup.min.js'
import './vendor/clamp.min.js'
